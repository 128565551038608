<template>

      
  <div class="detailbox">
    <div class=" flexcent title"> {{ data.title  }}  </div>
    <div>
      <el-form ref="form" :model="form" :rules="formRules" label-width="120px" >
                <el-form-item label="样品名称" prop="sampleName">
                    <el-input v-model="form.sampleName" placeholder="请输入样品名称" maxlength="80"/>
                </el-form-item>
                <el-form-item label="版本号" prop="sampleVersion">
                    <el-input v-model="form.sampleVersion" placeholder="请输入版本号" maxlength="80"/>
                </el-form-item>
                <el-form-item label="样品来源" prop="sampleSource">
                    <el-input v-model="form.sampleSource" placeholder="请输入样品来源" maxlength="80"/>
                </el-form-item>
                <el-form-item label="上传文件"   >

                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    action="/admin/sys-file/upload/apply"
                    :before-upload="beforeUpload"
                    :on-success="handleSuccess"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :file-list="form.files"
                    :auto-upload="true"
                    :drag="true"
                    :limit="5">
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                      <div slot="tip" class="el-upload__tip">上传文件不超过 1M, 文件数量不超过5个</div>
                  </el-upload>

                </el-form-item>
                <el-form-item label="单位名称" prop="corpName">
                 <el-input v-model="form.corpName" placeholder="请输入单位名称" maxlength="80"/>
                </el-form-item>
                <el-form-item label="联系人" prop="contactName">
                    <el-input v-model="form.contactName" placeholder="请输入联系人" maxlength="80"/>
                </el-form-item>
                <el-form-item label="联系电话" prop="contactPhone">
                    <el-input v-model="form.contactPhone" placeholder="请输入联系电话" maxlength="11"/>
                </el-form-item>
                <el-form-item label="电子邮件" prop="contactEmail">
                    <el-input v-model="form.contactEmail" placeholder="请输入电子邮件" maxlength="50"/>
                </el-form-item>
                <el-form-item label="描述" prop="describes">
                    <el-input type="textarea" :rows="4" v-model="form.describes" placeholder="请输入描述" />
                </el-form-item>
                <el-form-item  size="large" class="flexcent">
                    <el-button type="primary" :loading="submitLoading" @click="onSubmit">提交</el-button>
                     <el-button  @click="onCancel">取消</el-button>
                </el-form-item>
      </el-form>
    </div>
  </div>
   
      
    
</template>

<script>
// import crumbs from "@/components/all/crumbs"; //引入导航栏组件
// import htmlDetail from "@/components/all/htmlDetail"; //引入尾部组件
const FILE_MAX = 1
const FILE_MAX_SIZE = 1024*1024 * FILE_MAX

export default {
  name: 'AnalyseDetail',
  components: {
    // htmlDetail,
    // crumbs, //引入导航栏组件
  },

  data() {
    return {   
      id: '',
      data: '',
      submitLoading: false,
      // fileList: [],
      form:{
        id: 0,
        uid: '',
        industryId: '',
        industryName: '',
        // customerId: 0,
        sampleName: '',
        sampleVersion: '',
        sampleSource: '',
        corpName: "",
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        describes:'',
        files:[], 
      },
      formRules: {
        sampleName: [
          {required: true, message: "请输入样品名称!", trigger: "blur"},
          {min: 2, max: 20, message: "长度在 2 到 20 个字符!", trigger: "blur"}
        ],
        sampleVersion: [
          {required: true, message: "请输入版本号!", trigger: "blur"},
        ],
        corpName: [
          {required: true, message: "请输入单位名称!", trigger: "blur"},
        ],
        contactName: [
          { required: true, 
            message: "请输入联系人姓名!", 
            trigger: "blur",
            min: 2, 
            max: 20,
          },
        ],
        contactPhone: [
          {
            required: true, 
            min: 11,
            max: 11,
            message: "请输入11位手机号",
            trigger: "blur",
            pattern: "^(1[0-9]{10})$",
          },
        ],
        contactEmail: [
          {
            required: true,
            min: 6,
            max: 50, 
            message: "请输入联系人电子邮件!",
            trigger: "blur",
            pattern: "^[A-Za-z\\d]+([-_.][A-Za-z\\d]+)*@([A-Za-z\\d]+[-.])+[A-Za-z\\d]{2,5}$",
          },
        ],
      },           
    }
  },
  
  created() {
    let checkLogin = this.until.checkLogin();
    // console.log(" check login ", checkLogin)
    if(!checkLogin) {
      // console.log(" !checkLogin  , jumpt /tologin ", !checkLogin)
      let path = this.$route.path
      this.until.jump.call(this,'/tologin', "push", {title:"行业应用检验检测", redirect:path});
    } else {
      console.log(" userinfo", this.until.getUserInfo())
      this.form.uid = this.until.getUserInfo().id
      this.init()
      this.getdata()
    }
  },
  methods: {
    init(){
      this.id = this.$route.params.id
      console.log("   industry id " ,   this.id)
    },
    getdata() {
      this.$api.getInspectDetail(this.id).then((res) => {
        this.data = res.data;
        this.form.industryName = res.data.title;
        this.forn.industryId = this.id;
        console.log("  get data " , this.data)
      });
    },
    onCancel(){
      this.$router.push("../detail/"+this.id)
    },
     
    beforeUpload(file){
      console.log(" beforeUpload file ", file)
      if( file.size >FILE_MAX_SIZE) {
        this.$message.error('上传文件大小不能超过 ' + FILE_MAX + "M")
        return false
      }
     
    },
     submitUpload() {
        this.$refs.upload.submit()
      },
      handleRemove(file, fileList) {
        console.log("remove file", file, fileList);
      },
      handleSuccess(res, file, fileList){
          console.log(" sucess res ", res)
          console.log(" file ", file)
          console.log(" fileList ", fileList)
      },
    handlePreview(file) {
        console.log(file);
    },

    onSubmit(){
      this.$refs.form.validate(valid => {
        // console.log("----- addInspectOnline valid ", valid)
        if (valid) {
          this.addInspectOnline()
        } else {
           console.log(" 申请数据不完整：", this.form);
           this.$message.error("请输入完整的申请信息！");
        }
      });
    },

    addInspectOnline(){
      this.submitLoading = true
      this.$api.addInspectOnline(this.form).then(res => {
        this.submitLoading = false
        if(res.code==0 || res.code==200){
            this.$alert('提交申请成功，后续我们会联系您做进一步沟通', '申请检测', {
              confirmButtonText: '确定',
            }).then(()=>{
              console.log("confirm then")
               this.$router.back(-1)
            });
          //     this.$confirm('确定要删除吗?', '提示', {
          //     confirmButtonText: '确定',
          //     cancelButtonText: '取消',
          //     type: 'warning'
          //   }).then(() => {
          //       DeletePlayer(reqData).then(res=>{
          //   console.log(res)
          //  })
          //   }).catch(() => {
          //   });
          //  this.$message.info("提交申请成功，后续我们会联系您做进一步沟通！");
           console.log("adds")
          //  this.$router.back(-1)
        }
        else {
          this.$message.error("提交申请失败，请重试！");
        }
      }).catch(err=>{
        this.submitLoading = false
        console.error(" addInspectOnline ",err)
        this.$message.error("提交申请失败，请重试！");
      }) 
    },
  },
}
</script>

<style scoped lang="less">
  .title {
    height: 3.125rem;
    font-weight: 550;
    font-size: 1.5rem;
    padding-bottom: 10px 0 20px 0;
    // border-bottom: 0.0625rem solid #ececec;
  }
  

</style>
